var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","block":"","height":"30px"},on:{"click":_vm.open}},on),[_vm._v(" Importar ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Importar citas de Google ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('form',{attrs:{"action":"","data-vv-scope":"import-gmail-form"},on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_vm._v(" Con esta opción podrás importar los eventos de los calendarios que selecciones de Google dentro de las fechas indicadas como citas de dentro el sistema de Quickink. ")]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('label',[_vm._v("Desde")]),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{ 'error--text': _vm.data.from > _vm.data.to },attrs:{"outlined":"","dense":"","hide-details":"","value":_vm.data.from ? _vm.$d(new Date(_vm.data.from), 'date2digits') : null,"readonly":"","data-vv-validate-on":"change","data-vv-name":"from","error-messages":_vm.errors.collect('import-gmail-form.from')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mt-1",attrs:{"small":""}},[_vm._v("$appointments")])]},proxy:true}],null,true)},on))]}}])},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","color":"primary","no-title":""},model:{value:(_vm.data.from),callback:function ($$v) {_vm.$set(_vm.data, "from", $$v)},expression:"data.from"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('label',[_vm._v("Hasta")]),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{ 'error--text': _vm.data.from > _vm.data.to },attrs:{"outlined":"","dense":"","hide-details":"","value":_vm.data.to ? _vm.$d(new Date(_vm.data.to), 'date2digits') : null,"readonly":"","data-vv-validate-on":"change","data-vv-name":"to","error-messages":_vm.errors.collect('import-gmail-form.to')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mt-1",attrs:{"small":""}},[_vm._v("$appointments")])]},proxy:true}],null,true)},on))]}}])},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","color":"primary","no-title":""},model:{value:(_vm.data.to),callback:function ($$v) {_vm.$set(_vm.data, "to", $$v)},expression:"data.to"}})],1)],1),_c('v-col',{staticClass:"py-0"},[_c('label',[_vm._v("Calendarios")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"outlined":"","dense":"","multiple":"","hide-details":"","item-value":"id","item-text":"name","items":_vm.calendars,"data-vv-validate-on":"blur","data-vv-name":"calendars","error-messages":_vm.errors.collect('import-gmail-form.calendars')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index <= 0)?_c('span',[_vm._v(_vm._s(_vm.$tc(item.name)))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption ml-1"},[_vm._v(" (+"+_vm._s(_vm.data.calendars.length - 1)+" más) ")]):_vm._e()]}}]),model:{value:(_vm.data.calendars),callback:function ($$v) {_vm.$set(_vm.data, "calendars", $$v)},expression:"data.calendars"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"height":"25px","width":"100px"},attrs:{"outlined":"","elevation":"0"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{staticStyle:{"width":"100px !important","color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"type":"submit","elevation":"0"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"size":"14px"}},[_vm._v("$save")]),_vm._v(" "+_vm._s(_vm.$t("Importar", { name: _vm.$t("") }))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }