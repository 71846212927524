<template>
  <v-dialog width="500" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn outlined block v-on="on" @click="open" height="30px">
        Importar
      </v-btn>
    </template>
    <v-card>
      <v-card-title> Importar citas de Google </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form action data-vv-scope="import-gmail-form" @submit.prevent="save">
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              Con esta opción podrás importar los eventos de los calendarios que
              selecciones de Google dentro de las fechas indicadas como citas de
              dentro el sistema de Quickink.
            </v-col>
            <v-col cols="6" class="py-0">
              <label>Desde</label>
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    :value="
                      data.from ? $d(new Date(data.from), 'date2digits') : null
                    "
                    readonly
                    v-bind:class="{ 'error--text': data.from > data.to }"
                    data-vv-validate-on="change"
                    v-validate="'required'"
                    data-vv-name="from"
                    :error-messages="errors.collect('import-gmail-form.from')"
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon small class="mt-1">$appointments</v-icon>
                    </template>
                  </v-text-field>
                </template>

                <v-date-picker
                  first-day-of-week="1"
                  v-model="data.from"
                  color="primary"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="py-0">
              <label>Hasta</label>
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    :value="
                      data.to ? $d(new Date(data.to), 'date2digits') : null
                    "
                    readonly
                    v-bind:class="{ 'error--text': data.from > data.to }"
                    v-on="on"
                    data-vv-validate-on="change"
                    v-validate="'required'"
                    data-vv-name="to"
                    :error-messages="errors.collect('import-gmail-form.to')"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon small class="mt-1">$appointments</v-icon>
                    </template>
                  </v-text-field>
                </template>

                <v-date-picker
                  first-day-of-week="1"
                  v-model="data.to"
                  color="primary"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="py-0">
              <label>Calendarios</label>
              <v-select
                outlined
                dense
                v-model="data.calendars"
                multiple
                hide-details
                item-value="id"
                item-text="name"
                :items="calendars"
                data-vv-validate-on="blur"
                v-validate="'required'"
                data-vv-name="calendars"
                :error-messages="errors.collect('import-gmail-form.calendars')"
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index <= 0">{{ $tc(item.name) }}</span>
                  <span v-if="index === 1" class="grey--text text-caption ml-1">
                    (+{{ data.calendars.length - 1 }} más)
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="dialog = false"
            style="height: 25px; width: 100px"
            elevation="0"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("Importar", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ImportFromGoogleMassive",
  data() {
    return {
      data: {
        from: null,
        to: null,
        timezone: -120,
        calendars: [],
      },
      calendars: [],
      dialog: false,
    };
  },
  mounted() {
    //this.fetchCalendars();
  },
  methods: {
    ...mapActions("calendar", ["getCalendars", "importMassiveEvent"]),
    fetchCalendars() {
      ////console.log("fetch");
      this.getCalendars({
        account: 1,
      }).then((data) => {
        this.calendars = data.Google;
      });
    },
    open() {
      this.fetchCalendars();
      this.data = {
        from: null,
        to: null,
        timezone: -120,
        calendars: [],
      };
    },
    save() {
      this.$validator.validateAll("import-gmail-form").then((result) => {
        if (result)
          this.$confirm(
            "¿Estas seguro que quieres importar los eventos de estos eventos como citas? Este proceso podria tardar varios minutos"
          ).then(() => {
            this.importMassiveEvent(this.data).then((data) => {
              this.$alert("Importado correctamente");
              this.dialog = false;
            });
          });
      });
    },
  },
};
</script>